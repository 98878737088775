import React from 'react';

import helmet from '../../assets/images/bike-logo.png';
import hands from '../../assets/images/hands.png';
import { CardPlan } from '../../components/CardPlan';
import { OneSlideCarousel } from '../../components/OneSlideCarousel';
import { ScPage } from '../../components/page/styled';
import { TitleForCards } from '../../components/TitleForCards';
import { ScTop } from '../../components/Top/styled';
import { MainLayout } from '../../layouts/main-layout';
import {SEO} from "../../components/Seo/Seo";

const Plans = () => {
	return (
		<MainLayout>
			<ScPage>
				<ScTop disableHeight>
					<TitleForCards>
						<CardPlan
							img={helmet as string}
							altImg={'helmet'}
							label={'Self-Serve'}
							title={
								'Designed especially for small businesses that don’t have a dedicated HR function.'
							}
							text={
								'A streamlined application flow designed for employers who want to get tax-free savings straightaway, ' +
								'either for themselves or a specific employee.'
							}
							buttonRedirectUrl={`${process.env.GATSBY_BIKE_WEBSITE}/portal/employer_portal/register?plan=self-serve`}
						/>
						<CardPlan
							img={hands as string}
							altImg={'helmet'}
							label={'Managed'}
							title={'Designed for larger businesses with dedicated HR and People teams.'}
							text={
								'A managed service which has everything you need to effortlessly administer ' +
								'and promote a cycle to work scheme to colleagues.'
							}
							buttonRedirectUrl={`${process.env.GATSBY_BIKE_WEBSITE}/portal/employer_portal/register?plan=managed`}
						/>
					</TitleForCards>
					<OneSlideCarousel />
				</ScTop>
			</ScPage>
		</MainLayout>
	);
};

export default Plans;

export const Head = () => (
	<SEO
		title="Gogeta Cycle to Work Plans | Self-Serve & Managed Options"
		description="Gogeta offers tailored Cycle to Work plans for any business. Choose self-serve for small teams or managed service for large companies with dedicated HR support."
	/>
)
